<template>
  <!-- 
모달 컴포넌트가 23-04-27일자로 개편되어 훨씬 사용법이 간편해지고 로직도 더 개선되었음  

1. DYDialog 컴포넌트를 본체가 바로 사용하려고 할 때는 v-model로 직접 컨트롤한다
ex) <ModalDYDialog v-model:dialog-control="modalOpen"> // 여기서 modalOpen값은 ref데이터여야만 한다

2. DYDialog 컴포넌트를 중간단계 부모가 사용하는 경우, 부모에서 props를 받아서 해당 값으로 모달을 컨트롤해야만하는 경우
ex) <ModalDYDialog :dialog-control="modalToggle" @update:dialog-control="emit('update:modalToggle', $event)">
ㄴ이 경우 dialog-control에 들어가는 값은 props로 전달받은 모달 컨트롤 값일 것이며, 이는 데이터가 아니기에 v-model로 쓸 수 없기 때문에 해당 방법으로 처리한다
ㄴ이런 경우, v-model때 처럼 체인지 이벤트를 감지해서 알아서 처리되지는 못하기에 @update:dialog-control="emit('update:modalToggle', $event)" 이런 형태의 코드가 필요함. 
  (이 코드는 DYDialog내에서 쏴주는 이벤트 명은 변경해선 안되고!, 뒤의 emit의 이벤트 명은 :dialog-control에 들어간 props명과 일치해야 함)
ㄴ에밋을 사용하기 위해 emit설정은 필요함. update이벤트 명은 위에서 사용한 것과 동일해야 함
const emit = defineEmits<{
  (e: 'update:modalToggle', value: boolean): void,
}>()
ㄴ그리고 마지막으로 사용하는 최상위 부모에선 v-model:설정한 props명 이렇게 v-model로 넘겨야만 한다
 ex) <CommonDYUploader v-model:modalToggle="fileModal"></CommonDYUploader> 


-->

  <!-- 23년 12월. 이우석 : 뷰티파이의 모달 컴포넌트가 IOS환경에서 매우 늦게 동작하는 문제가 있어서 형태를 테일윈드 것으로 변경 -->
  <TransitionRoot v-if="type === 'normal' || type === 'bottom-sheet' || type === 'instant' || type === 'top'"
    attach=".v-application" as="template" :show="dialogControl">
    <Dialog as="div" class="relative z-[1050]" @close="close">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-[1050] overflow-y-auto" :class="fullScreen ? 'w-full h-full top-0 left-0' : ''">
        <div class="main-frame flex min-h-full justify-center text-center items-center sm:p-0 "
          :class="computedAlign + ' ' + computedMainClass">
          <!-- <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"> -->

          <DialogPanel
            :style="{ width: computedWidth, maxHeight: maxHeight ? maxHeight : undefined, height: height ? height : undefined }"
            class="relative overflow-hidden rounded-lg bg-white dark:!bg-zinc-800 dark:!text-white text-left shadow-xl transition-all sm:w-full "
            :class="computedPanelClass + (hasScroll ? ' overflow-y-auto' : '') + (fullScreen ? ' h-[100vh]' : ' transform sm:my-8')">
            <!-- overflow-y-auto 자체는 인스턴트 메시지 등 너무 내용이 길어진 모달에서 스크롤 주기 위함임. 원래는 overflow-y-scroll을 줬다가 필요하지 않은 사이즈에서도 다 스크롤이 생겨서 auto로 수정 -->

            <slot v-if="header && type !== 'bottom-sheet'" name="header">
              <div class="modal-header redDDBack whiteFont p-4" :class="fullScreen ? ' fixed w-full left-0 top-0' : ''"
                style="background-color: #c50000; z-index: 10">
                <div style="margin-left: 0.625rem;">
                  <div style="display: table-cell; width: 20%;"></div>
                  <div class="flex justify-between items-center">
                    <h3 class="modal-title text-gray-200 fontW600"
                      style="vertical-align: middle; padding-left: .25rem; font-size: 20px;">
                      <v-icon :icon="`${icon.prefix} fa-${icon.iconName}`"
                        style="font-size: 19px; margin-right: 5px;" /> {{
                          convertTitle }}
                    </h3>
                    <v-icon @click="emit('update:dialogControl', false)" class="text-white " icon="far fa-times"
                      style="font-size: 22px;" />
                  </div>
                </div>
              </div>
            </slot>

            <div :class="fullScreen ? 'mt-16' : ''">
              <slot></slot>
            </div>

            <div v-if="footer" class="bg-gray-50  px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
              <slot name="footer">
                <v-btn size="small" @click="clickFunc" :disabled="isProcessing" color="blue">확인</v-btn>
                <v-btn size="small" color="neutral" @click="emit('update:dialogControl', false)"
                  ref="cancelButtonRef">취소</v-btn>
              </slot>
            </div>
          </DialogPanel>

          <!-- </TransitionChild> -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- 심플 타입인 경우에만 쓰고 있음 -->
  <TransitionRoot v-else attach=".v-application" as="template" :show="dialogControl">
    <Dialog as="div" class="relative z-[1050]" @close="!persistent ? emit('update:dialogControl', false) : undefined">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-[1050] " :class="hasScroll ? ' overflow-y-auto' : ''">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel :style="{ width: computedWidth }"
              class="relative transform overflow-hidden rounded-lg bg-white dark:!bg-zinc-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-white dark:!bg-zinc-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <v-icon :icon="`${icon.prefix} fa-${icon.iconName}`" style="font-size: 22px;" />
                    <!-- <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" /> -->
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ convertTitle }}
                    </DialogTitle>
                    <div class="mt-2 text-sm text-gray-500 dark:text-white">
                      <slot></slot>
                    </div>
                  </div>
                  <!-- <v-icon @click="emit('update:dialogControl', false)" class="text-white" icon="far fa-times" style="font-size: 22px;" /> -->
                </div>
              </div>

              <div v-if="footer" class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-2">
                <slot name="footer">
                  <v-btn size="small" @click="clickFunc" :disabled="isProcessing" color="blue">확인</v-btn>
                  <v-btn size="small" color="neutral" @click="emit('update:dialogControl', false)"
                    ref="cancelButtonRef">취소</v-btn>
                </slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
// import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { library, type IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPencil, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useDYAppConfig } from '~/modules/nuxt3-module-doyac/composables/config';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useDYClosableByBack } from '~/modules/nuxt3-module-doyac/composables/dy-closable-by-back';
import { useDYWindowStore } from '~/modules/nuxt3-module-doyac/stores/window';

library.add(faPencil, faTimes)

const { siteName } = useDYAppConfig()

const props = withDefaults(defineProps<{
  /**
   * 사용하려는 곳에서 v-model:dialog-control="dialog" 이 형태로 넘길것. dialog는 부모의 ref데이터로 선언필요
   */
  dialogControl: boolean
  width?: string
  height?: string
  maxHeight?: string
  submitEvent?: (param?: any) => void
  title?: string
  titleIcon?: IconDefinition
  hasScroll?: boolean,
  header?: boolean
  footer?: boolean
  isClosableByBack?: boolean
  /**
   * 모달 밖 배경을 클릭했을 때 닫을 것인가. 기본은 닫히도록 되어 있음
   */
  persistent?: boolean
  /**
   * 컨텐츠 양에 따라서 지정하면 됨
   */
  type?: 'normal' | 'small' | 'bottom-sheet' | 'top' | 'instant'
  sendClass?: string
  isProcessing?: boolean
  fullScreen?: boolean
}>(), {
  dialogControl: () => false,
  useInject: () => false,
  width: () => '600px',
  header: () => true,
  footer: () => false,
  type: () => 'normal',
  isClosableByBack: () => true,
  persistent: () => false,
  hasScroll: () => true,
  isProcessing: () => false,
  fullScreen: () => false
})

const windowStore = useDYWindowStore()

const convertTitle = computed(() => {
  return props.title ?? siteName
})

const computedWidth = computed(() => {
  if (props.fullScreen) return '100%';
  return props.width ?? '500px'
})

const computedAlign = computed(() => {
  if (props.type === 'bottom-sheet') return '!items-end'
  if (props.type === 'top') return '!items-start'
  return ''
})

const computedMainClass = computed(() => {
  let className = ''
  if (props.type === 'bottom-sheet') {

  } else {
    className = 'p-4'
  }
  return className
})

const computedPanelClass = computed(() => {
  let className = ''
  if (props.type === 'bottom-sheet') {
    className = '!mb-0 !max-w-[1100px] '
    className += windowStore.windowWidth > 1100 ? '!w-[70%] ' : '!w-full '
  } else if (props.type === 'instant') {
    className = '!fixed right-0 bottom-0 !mb-0'
  }
  return className
})

const icon = computed(() => {
  return props.titleIcon ?? faPencil
})

const emit = defineEmits<{
  (e: 'confirm-modal-submit', value?: any): void //여기서의 밸류는 부모단계에서 뭘 넘겨주느냐에 따라 달리므로..
  (e: 'update:dialogControl', value: boolean): void
}>()

const clickFunc = () => {
  if (props.submitEvent) {
    props.submitEvent()
  } else {
    emit('confirm-modal-submit')
  }
}

const close = ($event: boolean) => {
  !props.persistent ? emit('update:dialogControl', false) : undefined
}

if (props.isClosableByBack) {
  useDYClosableByBack(computed(() => props.dialogControl), () => {
    emit('update:dialogControl', false)
  })
}

</script>

<style scoped>
.dy-dialog:deep(.v-overlay__content) {
  overflow-y: auto;
}
</style>